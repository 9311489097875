import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Icons } from './Icons';
import styles from './Footer.module.css';
import FeatureFlag from './FeatureFlag';

const CURRENT_GAME = 'CONTEXTO';

function Game({ logo, name, url, releaseDate }) {
  const { t } = useTranslation();
  if (name === CURRENT_GAME) return null;
  if (dayjs().isBefore(dayjs(releaseDate))) return null;
  let isNew = false;
  if (dayjs().diff(dayjs(releaseDate), 'months') < 1) {
    isNew = true;
  }
  return (
    <a
      className={styles.game}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={styles.gameLogo}>{logo}</div>
      <div className={styles.gameName}>{name}</div>
      {isNew && <div className={styles.newTag}>{t('footer.new')}</div>}
    </a>
  );
}

const LOGO_SIZE = 20;
const LETROSO_RELEASE_DATE = '2024-09-01';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer" style={{ marginTop: 40 }}>
      <div className="company-logo">
        <a href="https://daydash.com" target="_blank" rel="noopener noreferrer">
          <Icons.Daydash />
        </a>
      </div>
      <FeatureFlag releaseDate={LETROSO_RELEASE_DATE}>
        <div className={styles.moreGames}>{t('footer.moreGames')}</div>
        <div className={styles.gamesList}>
          <Game
            logo={<Icons.Contexto size={LOGO_SIZE} />}
            name="CONTEXTO"
            url="https://contexto.me"
            releaseDate="2022-02-23"
          />
          <Game
            logo={<Icons.Conexo size={LOGO_SIZE} />}
            name="CONEXO"
            url="https://conexo.ws"
            releaseDate="2023-10-01"
          />
          <Game
            logo={<Icons.Letroso size={LOGO_SIZE} />}
            name="LETROSO"
            url="https://letroso.com"
            releaseDate={LETROSO_RELEASE_DATE}
          />
        </div>
      </FeatureFlag>
      <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
        {t('footer.privacyPolicy')}
      </a>
    </div>
  );
}

export default Footer;
