/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';

const styles = {
  item: {
    display: 'flex',
    margin: '20px 0',
  },
  itemName: {
    minWidth: '30%',
    paddingRight: 10,
  },
  itemOptions: {
    maxWidth: '70%',
  },
  container: {
    display: 'flex',
    cursor: 'pointer',
    paddingBottom: 10,
  },
  input: {
    paddingRight: 10,
  },
  description: {
    fontSize: 14,
    opacity: 0.8,
  },
};

function Settings({
  tipSetting,
  onTipSettingChange,
  language,
  onLanguageChange,
  theme,
  onThemeChange,
  order,
  onOrderChange,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="modal-title">
        <Icons.Settings />
        <h2>{t('Settings')}</h2>
      </div>

      <div style={styles.item}>
        <div style={styles.itemName}>{t('Language')}</div>
        <div style={styles.itemOptions}>
          <div style={styles.container} onClick={() => onLanguageChange('pt')}>
            <div style={styles.input}>
              <input type="radio" checked={language === 'pt'} readOnly />
            </div>
            <div>
              <div>Português</div>
              <div style={styles.description} />
            </div>
          </div>
          <div style={styles.container} onClick={() => onLanguageChange('en')}>
            <div style={styles.input}>
              <input type="radio" checked={language === 'en'} readOnly />
            </div>
            <div>
              <div>English</div>
              <div style={styles.description} />
            </div>
          </div>
          <div style={styles.container} onClick={() => onLanguageChange('es')}>
            <div style={styles.input}>
              <input type="radio" checked={language === 'es'} readOnly />
            </div>
            <div>
              <div>Español</div>
              <div style={styles.description} />
            </div>
          </div>
        </div>
      </div>

      <div style={styles.item}>
        <div style={styles.itemName}>{t('settingsModal.theme')}</div>
        <div style={styles.itemOptions}>
          <div style={styles.container} onClick={() => onThemeChange('light')}>
            <div style={styles.input}>
              <input type="radio" checked={theme === 'light'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.light')}</div>
              <div style={styles.description} />
            </div>
          </div>
          <div style={styles.container} onClick={() => onThemeChange('dark')}>
            <div style={styles.input}>
              <input type="radio" checked={theme === 'dark'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.dark')}</div>
              <div style={styles.description} />
            </div>
          </div>
        </div>
      </div>

      <div style={styles.item}>
        <div style={styles.itemName}>{t('settingsModal.tips')}</div>
        <div style={styles.itemOptions}>
          <div
            style={styles.container}
            onClick={() => onTipSettingChange('half')}
          >
            <div style={styles.input}>
              <input type="radio" checked={tipSetting === 'half'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.easy')}</div>
              <div style={styles.description}>
                {t('settingsModal.easyExplanation')}
              </div>
            </div>
          </div>
          <div
            style={styles.container}
            onClick={() => onTipSettingChange('next')}
          >
            <div style={styles.input}>
              <input type="radio" checked={tipSetting === 'next'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.medium')}</div>
              <div style={styles.description}>
                {t('settingsModal.mediumExplanation')}
              </div>
            </div>
          </div>
          <div
            style={styles.container}
            onClick={() => onTipSettingChange('random')}
          >
            <div style={styles.input}>
              <input type="radio" checked={tipSetting === 'random'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.hard')}</div>
              <div style={styles.description}>
                {t('settingsModal.hardExplanation')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={styles.item}>
        <div style={styles.itemName}>{t('settingsModal.order')}</div>
        <div style={styles.itemOptions}>
          <div
            style={styles.container}
            onClick={() => onOrderChange('similarity')}
          >
            <div style={styles.input}>
              <input type="radio" checked={order === 'similarity'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.similarity')}</div>
              <div style={styles.description} />
            </div>
          </div>
          <div style={styles.container} onClick={() => onOrderChange('guess')}>
            <div style={styles.input}>
              <input type="radio" checked={order === 'guess'} readOnly />
            </div>
            <div>
              <div>{t('settingsModal.guess')}</div>
              <div style={styles.description} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Settings };
