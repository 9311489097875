import dayjs from 'dayjs';

function FeatureFlag({ releaseDate, children }) {
  if (dayjs().isAfter(dayjs(releaseDate).startOf('day'))) {
    return children;
  }
  return null;
}

export default FeatureFlag;
