import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Icons } from './Icons';
import styles from './DailyAnnouncement.module.css';

const RELEASE_DATE = '2024-09-01';
const EXPIRATION_DATE = '2024-09-15';

function DailyAnnouncement() {
  const { t } = useTranslation();

  if (dayjs().isBefore(dayjs(RELEASE_DATE).startOf('day'))) {
    return null;
  }

  if (dayjs().isAfter(dayjs(EXPIRATION_DATE).startOf('day'))) {
    return null;
  }

  return (
    <div className="end-msg" style={{ marginTop: 0 }}>
      <p>
        <b>{t('announcement.content')}</b>
      </p>
      <p>{t('announcement.content2')}</p>
      <div className={styles.game}>
        <div className={styles.gameLogo}>
          <Icons.Letroso size={20} />
        </div>
        <div className={styles.gameName}>LETROSO</div>
      </div>
      <div className="end-share-wrapper" style={{ marginTop: 20 }}>
        <a
          className="button"
          href="https://letroso.com"
          target="_blank"
          rel="noreferrer"
        >
          {t('announcement.action')}
        </a>
      </div>
    </div>
  );
}

export default DailyAnnouncement;
