import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Loading';
import { WordList } from '../WordList';

function Current({ gameId, loading, data }) {
  const { t } = useTranslation();

  if (loading) {
    return <Loading text={t('loading')} />;
  }

  if (data.length < 1) {
    return <p>{t('oops')}</p>;
  }

  const words = data.map((word, i) => [word, i]);

  return (
    <div>
      <p>
        {t('closestModal.p1a')} (<b>#{gameId}</b>) {t('closestModal.p1b')}
      </p>
      <p style={{ textAlign: 'center', fontSize: 20, marginBottom: 15 }}>
        <b>{data[0]}</b>
      </p>
      <p>{t('closestModal.p2', { number: data.length })}</p>
      <div>
        <WordList words={words} />
      </div>
    </div>
  );
}

export { Current };
