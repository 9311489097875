import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getTodaysGameId } from './utils/misc';
import { useLocalStorage } from './useLocalStorage';
import { track } from './utils/analytics';

const initialGameData = {
  gameId: 0,
  guessHistory: [],
  lastGuess: null,
  foundWord: '',
  numberOfTips: 0,
  numberOfAttempts: 0,
  gaveUp: '',
  postGameHistory: [],
};

const initialState = {
  lastGameId: 0,
  openGameId: 0,
  gameData: {
    pt: {},
    en: {},
    es: {},
  },
  theme: undefined,
  language: undefined,
  version: 2,
};

const convertPreviousVersion = (previousState) => {
  const newState = { ...initialState };

  const { gameId } = previousState;
  newState.lastGameId = gameId;
  newState.openGameId = gameId;
  newState.gameData.pt = { ...previousState.gameData };

  return newState;
};

const selectInitialLanguage = (previousState) => {
  const { language } = previousState;
  if (language === 'pt' || language === 'en' || language === 'es') {
    return previousState;
  }
  if (Object.keys(previousState.gameData?.pt).length > 0) {
    return { ...previousState, language: 'pt' };
  }
  if (Object.keys(previousState.gameData?.en).length > 0) {
    return { ...previousState, language: 'en' };
  }
  if (Object.keys(previousState.gameData?.es).length > 0) {
    return { ...previousState, language: 'es' };
  }
  const preferredLanguages = navigator.languages;
  if (!preferredLanguages || preferredLanguages.length === 0) {
    return { ...previousState, language: 'en' };
  }
  const foundPreferred = preferredLanguages.find(
    (l) => l.includes('en') || l.includes('pt') || l.includes('es')
  );
  if (foundPreferred) {
    let preferredLanguage;
    if (foundPreferred.includes('en')) {
      preferredLanguage = 'en';
    } else if (foundPreferred.includes('es')) {
      preferredLanguage = 'es';
    } else {
      preferredLanguage = 'pt';
    }
    return { ...previousState, language: preferredLanguage };
  }
  return { ...previousState, language: 'en' };
};

const selectMostRecentGame = (previousState) => {
  const { language } = previousState;
  let newState = { ...previousState };

  const todaysGameId = getTodaysGameId(language);
  const { lastGameId } = newState;

  if (todaysGameId !== lastGameId) {
    newState = {
      ...newState,
      lastGameId: todaysGameId,
      openGameId: todaysGameId,
    };
  }

  if (
    newState.gameData[language] === undefined ||
    newState.gameData[language][todaysGameId] === undefined
  ) {
    newState = {
      ...newState,
      gameData: {
        ...newState.gameData,
        [language]: {
          ...newState.gameData[language],
          [todaysGameId]: {
            ...initialGameData,
            gameId: todaysGameId,
            // ...getTestGameData(),
          },
        },
      },
    };
  }
  return newState;
};

function useGameState() {
  const [loading, setLoading] = useState(true);
  const [savedState, setSavedState] = useLocalStorage('state', initialState);
  const { i18n } = useTranslation();

  const changeSystemsLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    dayjs.locale(newLanguage);
  };

  useEffect(() => {
    const { version: latestVersion } = initialState;
    const { version: currentVersion } = savedState;

    let latestVersionState = { ...savedState };

    if (currentVersion < latestVersion - 1 || currentVersion > latestVersion) {
      // Reset state to a clean initial version
      latestVersionState = { ...initialState };
    } else if (currentVersion === latestVersion - 1) {
      latestVersionState = convertPreviousVersion(savedState);
    }

    latestVersionState = selectInitialLanguage(latestVersionState);
    changeSystemsLanguage(latestVersionState.language);
    latestVersionState = selectMostRecentGame(latestVersionState);

    setSavedState(latestVersionState);
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setLanguage = (newLanguage) => {
    let newState = { ...savedState, language: newLanguage };
    newState = selectMostRecentGame(newState);
    changeSystemsLanguage(newLanguage);
    setSavedState(newState);
    track.languageSetting(newLanguage);
  };

  return {
    loading,
    gameState: savedState,
    setGameState: setSavedState,
    setLanguage,
  };
}

export { useGameState };
