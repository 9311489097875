import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';

const feedbackFormUrl = {
  pt: 'https://docs.google.com/forms/d/e/1FAIpQLSedr9Hx6ZhoALP02Cgh8j-77yd7jGJrGxBtdnjtJ7zm8f19QQ/viewform',
  en: 'https://docs.google.com/forms/d/e/1FAIpQLSf_acy442tZY3LuRW1oArOXPzO4r4jMq3GrEaR9xEQTzLJ4nQ/viewform',
  es: 'https://docs.google.com/forms/d/e/1FAIpQLSfB5hfO1q2cTwaexu3HAAo9VExgGh68VlXVmNm3WBJJ-pYQFQ/viewform',
};

function Feedback(props) {
  const { t } = useTranslation();
  const { language } = props;

  const formLink = feedbackFormUrl[language];

  const handleButtonClick = () => {
    window.open(formLink, '_blank');
  };

  return (
    <div>
      <div className="modal-title">
        <Icons.Chat />
        <h2>{t('FeedbackTitle')}</h2>
      </div>
      <p>{t('FeedbackText.pt1')}</p>
      <p>{t('FeedbackText.pt2')}</p>
      <div className="modal-btn-div">
        <button type="button" className="button" onClick={handleButtonClick}>
          {t('FeedbackButton')}
        </button>
      </div>
    </div>
  );
}

export { Feedback };
