import React from 'react';

function Loading({ text }) {
  return (
    <div className="loading-text">
      {text.split('').map((character, i) => (
        <span
          key={`${character}-${i}`}
          style={{ '--i': i }}
          className="visible"
        >
          {character === ' ' ? '\u00a0' : character}
        </span>
      ))}
    </div>
  );
}

export { Loading };
