import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactFAQ from 'react-faq-component';
import { Icons } from '../Icons';

function FAQ({ limit }) {
  const { t } = useTranslation();

  const rows = [
    {
      title: t('faq.q1'),
      content: t('faq.a1'),
    },
    {
      title: t('faq.q2'),
      content: t('faq.a2'),
    },
    {
      title: t('faq.q3'),
      content: t('faq.a3'),
    },
    {
      title: t('faq.q4'),
      content: t('faq.a4'),
    },
    {
      title: t('faq.q5'),
      content: t('faq.a5'),
    },
    {
      title: t('faq.q6'),
      content: t('faq.a6'),
    },
  ];

  const shownRows = rows.slice(0, limit);

  const data = {
    rows: shownRows,
  };

  return (
    <div>
      <div className="modal-title">
        <Icons.FAQ />
        <h2>{t('faq.title')}</h2>
      </div>
      <ReactFAQ data={data} />
    </div>
  );
}

export { FAQ };
