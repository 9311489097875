import React from 'react';
import { Row } from './Row';

function WordList({ words, highlights = [], order = 'similarity' }) {
  const sortedWords = words.slice();

  if (order === 'similarity') {
    sortedWords.sort((a, b) => a[1] - b[1]);
  }

  return (
    <div className="guess-history">
      {sortedWords.map((item) => (
        <Row
          key={item[0]}
          word={item[0]}
          distance={item[1]}
          highlight={highlights.includes(item[0])}
        />
      ))}
    </div>
  );
}

export { WordList };
