import React from 'react';
import { Icons } from './Icons';

function Modal({ onClose, children, closeOnMaskClick = true }) {
  let onMaskClick = () => {};

  if (closeOnMaskClick) {
    onMaskClick = onClose;
  }

  return (
    <div
      className="modal-bg top-ad-padding bottom-ad-padding"
      onClick={onMaskClick}
    >
      <div className="modal-wrapper">
        <div className="modal-close-button" onClick={onClose}>
          <Icons.Close />
        </div>
        <div
          className="modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export { Modal };
