import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../components/Icons';
import classes from './OtherGames.module.css';

const CURRENT_GAME = 'CONTEXTO';

function VerticalGameLink({ logo, name, url }) {
  if (name === CURRENT_GAME) return null;
  return (
    <a className={classes.verticalGame} href={url}>
      <div className={classes.gameLogo}>{logo}</div>
      <div className={classes.gameName}>{name}</div>
    </a>
  );
}

const LOGO_SIZE = 32;

function OtherGames() {
  const { t } = useTranslation();

  return (
    <div className={classes.otherGamesWrapper}>
      <div className={classes.otherGames}>
        <div className={classes.moreGames}>{t('otherGames.moreGames')}</div>
        <div className={classes.gamesList}>
          <VerticalGameLink
            logo={<Icons.Contexto size={LOGO_SIZE} />}
            name="CONTEXTO"
            url="https://contexto.me"
          />
          <VerticalGameLink
            logo={<Icons.Conexo size={LOGO_SIZE} />}
            name="CONEXO"
            url="https://conexo.ws"
          />
          <VerticalGameLink
            logo={<Icons.Letroso size={LOGO_SIZE} />}
            name="LETROSO"
            url="https://letroso.com"
          />
        </div>
      </div>
    </div>
  );
}

export default OtherGames;
