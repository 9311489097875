import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';

function Instructions() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="modal-title">
        <Icons.QuestionMark />
        <h2>{t('How to play')}</h2>
      </div>
      <p>{t('howToPlayText.p1')}</p>
      <p>{t('howToPlayText.p2')}</p>
      <p>{t('howToPlayText.p3')}</p>
      <p>{t('howToPlayText.p4')}</p>
    </div>
  );
}

export { Instructions };
