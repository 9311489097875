import React, { useState } from 'react';

function Menu({ children }) {
  const [visible, setVisible] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <button
        type='button'
        className={`btn ${visible ? 'btn-active' : ''}`}
        onClick={() => setVisible(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
        </svg>
      </button>
      {visible && (
        <>
          <div className="dropdown-bg" onClick={() => setVisible(false)} />
          <div className="dropdown" onClick={() => setVisible(false)}>
            {children}
          </div>
        </>
      )}
    </div>
  );
}

export { Menu };
