const GameApi = (language, gameId) => {
  const baseUrl = 'https://contexto.bioticnova.de/api/machado';

  const play = (word) => fetch(`${baseUrl}/${language}/game/${gameId}/${word}`);

  const tip = (distance) =>
    fetch(`${baseUrl}/${language}/tip/${gameId}/${distance}`);

  const giveUp = () => fetch(`${baseUrl}/${language}/giveup/${gameId}`);

  const getClosestWords = () => fetch(`${baseUrl}/${language}/top/${gameId}`);

  return {
    play,
    tip,
    giveUp,
    getClosestWords,
  };
};

export default GameApi;
